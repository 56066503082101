// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --background-color: #262626;
  --text-color: #8d8d8d;
  --card-background-color: #424242;
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: "Roboto", sans-serif;
}

.App {
  height: 100vh;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,qBAAA;EACA,gCAAA;AACF;;AAGA;EACI,UAAA;EACA,SAAA;EACA,yCAAA;EACA,wBAAA;EACA,iCAAA;AAAJ;;AAGA;EACE,aAAA;EACA,kBAAA;AAAF","sourcesContent":[":root {\n  --background-color: #262626;\n  --text-color: #8d8d8d;\n  --card-background-color: #424242;\n}\n\n\nbody {\n    padding: 0;\n    margin: 0;\n    background-color: var(--background-color);\n    color: var(--text-color);\n    font-family: 'Roboto', sans-serif;\n}\n\n.App {\n  height: 100vh; // This makes sure your app takes up the full height of the viewport\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
