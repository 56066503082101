import * as React from "react";
import "./AppCard.scss";


interface AppCardProps {
    name: string;
    icon: string;
    link: string;
}

const AppCard: React.FC<AppCardProps> = ({ name, icon, link }) => {
    return (
        <div className="app-card">
            <a href={link}>
                <img src={icon} alt={name} />
                {name}
            </a>
        </div>
    );
};

export default AppCard;