import * as React from "react";
import AppCard from "./AppCard";
import "./AppGrid.scss";

// Icons
import bssIcon = require("@public/bss-icon.png");
import ocrIcon = require("@public/ocr.png");


const AppGrid = () => {
    const apps = [
        { name: "BSS", icon: bssIcon, link: "https://bss.apps.spillmaker.no" },
        // { name: "OCR", icon: ocrIcon, link: "https://ocr.apps.spillmaker.no" },
        // Add more apps here...
    ];

    return (
        <div className="app-grid">
            {apps.map((app, index) => (
                <AppCard key={index} name={app.name} icon={app.icon} link={app.link} />
            ))}
        </div>
    );
};

export default AppGrid;