// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
}

.app-card img {
  width: 100px;
  height: 100px;
  border-radius: 25%;
  margin-bottom: 10px;
}

.app-card a {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  text-decoration: none;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.app-card a:hover {
  transform: scale(1.05);
}`, "",{"version":3,"sources":["webpack://./src/Components/AppCard.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,qBAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;EACA,2DAAA;AACF;;AAGA;EACE,sBAAA;AAAF","sourcesContent":[".app-card {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n  border-radius: 10px;\n}\n\n.app-card img {\n  width: 100px;\n  height: 100px;\n  border-radius: 25%;\n  margin-bottom: 10px;\n}\n\n.app-card a {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 10px;\n  text-decoration: none;\n  font-weight: bold;\n  color: #ffffff;\n  text-align: center;\n  transition: background-color 0.3s ease, transform 0.3s ease; // This will animate the background color and scale changes\n\n}\n\n.app-card a:hover {\n  transform: scale(1.05); // This will scale the card up to 105% of its original size\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
