import * as React from "react";
import { createRoot } from "react-dom/client";
import {BrowserRouter, createBrowserRouter, Route, RouterProvider, Routes} from "react-router-dom";
import App from "./App";


const root = createRoot(document.getElementById("app"));

root.render(
    <BrowserRouter>
        <Routes>
            <Route path={"/"} element={<App/>} />
            <Route path={"*"} element={<h1>Other</h1>} />
        </Routes>
    </BrowserRouter>
);

