import * as React from "react";
import "./App.scss";
import AppGrid from "./Components/AppGrid";

const App = () => {
    return <div className={"App"}>
        <h1>Spillmaker Applications</h1>
        <AppGrid />
    </div>;
};
export default App;